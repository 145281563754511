import { graphql, useStaticQuery } from "gatsby";

import { Button, StickyBottomData, WinningText } from "../../../types/types";

const processStickyBottomData = (data: any): StickyBottomData => {
  const myData = data.allWpPage.edges[0].node.stickybottom.downloadbuttondata
  const winningTextData = data?.allWpPage?.edges[0]?.node?.stickybottom?.imagewithtext
  const button = myData?.button
  const winningText: WinningText = {
    text: winningTextData?.description,
    image: { imageFile: winningTextData?.image?.imagefile?.localFile?.url, altText: winningTextData?.image?.alttext }
  }
  const joiningBonusText = {
    text: button.bonustext.text,
    color: button.bonustext.color
  }
  const joiningbonusTextLs = {
    text: button.bonustextls.text,
    color: button.bonustextls.color
  }
  const joiningbonusTextLsSixteen = {
    text: button.bonustextlssixteen.text,
    color: button.bonustextlssixteen.color
  }
  const joiningBonusTextHindi = {
    text: button.bonustexthindi.text,
    color: button.bonustexthindi.color
  }
  const joiningBonusTextBengali = {
    text: button.bonustextbengali.text,
    color: button.bonustextbengali.color
  }
  
  const joiningBonusTextLs14Oriya = {
    text: button.bonustextls14oriya.text,
    color: button.bonustextls14oriya.color
  }

  const joiningBonusTextLs14Punjabi = {
    text: button.bonustextls14punjabi.text,
    color: button.bonustextls14punjabi.color
  }

  const joiningBonusTextLs14Malayalam = {
    text: button.bonustextls14malayalam.text,
    color: button.bonustextls14malayalam.color
  }

  const joiningBonusTextKannada = {
    text: button.bonustextkannada.text,
    color: button.bonustextkannada.color
  }
  const joiningBonusTextTamil = {
    text: button.bonustexttamil.text,
    color: button.bonustexttamil.color
  }
  const joiningBonusTextBhojpuri = {
    text: button.bonustextbhojpuri.text,
    color: button.bonustextbhojpuri.color
  }
  const joiningBonusTextGujarati = {
    text: button.bonustextgujarati.text,
    color: button.bonustextgujarati.color
  }
  const joiningBonusTextMarathi = {
    text: button.bonustextmarathi.text,
    color: button.bonustextmarathi.color
  }
  const joiningbonusTextPlaystoreHindi = {
    text: button.bonustextplaystorehindi.text,
    color: button.bonustextplaystorehindi.color
  }
  const joiningBonusTextLsGoogle = {
    text: button.bonustextlsgoogle.text,
    color: button.bonustextlsgoogle.color
  }
  const joiningBonusTextLsNinePage = {
    text: button.bonustextlsninepage.text,
    color: button.bonustextlsninepage.color
  }
  const joiningBonusTextLsFourPage = {
    text: button.bonustextlsfourpage.text,
    color: button.bonustextlsfourpage.color
  }

  const joiningBonusTextLsTwelvePage = {
    text: button.bonustextlstwelvepage.text,
    color: button.bonustextlstwelvepage.color
  }
  const joiningBonusTextLsElevenPage = {
    text: button.bonustextlselevenpage.text,
    color: button.bonustextlselevenpage.color
  }
  const joiningBonusTextLsEightPage = {
    text: button.bonustextlseightpage.text,
    color: button.bonustextlseightpage.color
  }

  
  
  const buttonData: Button = {
    buttonColor: button?.buttoncolor,
    buttonText: button?.buttontext,
    buttonTextHindi: button?.buttontexthindi,
    buttonTextForiOS: button?.buttontextforios,
    buttonTextBengali: button?.buttontextbengali,
    buttonTextLs14Oriya: button?.buttontextls14oriya,
    buttonTextLs14Punjabi: button?.buttontextls14punjabi,
    buttonTextLs14Malayalam: button?.buttontextls14malayalam,
    buttonTextLs12: button?.buttontextlstwelve,
    buttonTextLs8: button?.buttontextlseight,
    buttonTextKannada: button?.buttontextkannada,
    buttonTextTamil: button?.buttontexttamil,
    buttonTextBhojpuri: button?.buttontextbhojpuri,
    buttonTextGujarati: button?.buttontextgujarati,
    buttonTextMarathi: button?.buttontextmarathi,
    buttonIosLink: myData?.buttonioslink,
    buttonAndroidLink: myData?.buttonandroidlink,
    buttonAndroidImage: { imageFile: myData?.buttonandroidimage.imagefile?.localFile?.childImageSharp?.gatsbyImageData, altText: myData?.buttonandroidimage.alttext },
    buttonIosImage: { imageFile: myData?.buttoniosimage.imagefile?.localFile?.childImageSharp?.gatsbyImageData, altText: myData?.buttoniosimage.alttext },
    buttonBackgroundImage: { imageFile: button?.buttonbackground?.imagefile?.localFile?.childImageSharp?.gatsbyImageData, altText: button?.buttonbackground?.alttext },
    buttonColorStart: button?.buttoncolorstart,
    buttonColorEnd: button?.buttoncolorend
  }
  const stickyBarData: StickyBottomData = {
    backgroundColor: myData.background.color,
    backgroundImage: myData?.background?.image?.localFile?.childImageSharp?.gatsbyImageData,
    downloadButtonData: {
      button: buttonData
    },
    joiningBonusText: joiningBonusText,
    joiningBonusTextLs: joiningbonusTextLs,
    joiningBonusTextLsSixteen: joiningbonusTextLsSixteen,
    joiningBonusTextHindi: joiningBonusTextHindi,
    joiningBonusTextBengali: joiningBonusTextBengali,
    joiningBonusTextLs14Oriya: joiningBonusTextLs14Oriya,
    joiningBonusTextLs14Punjabi: joiningBonusTextLs14Punjabi,
    joiningBonusTextLs14Malayalam: joiningBonusTextLs14Malayalam,
    joiningBonusTextKannada: joiningBonusTextKannada,
    joiningBonusTextTamil: joiningBonusTextTamil,
    joiningBonusTextBhojpuri: joiningBonusTextBhojpuri,
    joiningBonusTextGujarati: joiningBonusTextGujarati,
    joiningBonusTextMarathi: joiningBonusTextMarathi,
    joiningbonusTextPlaystoreHindi: joiningbonusTextPlaystoreHindi,
    joiningBonusTextLsGoogle: joiningBonusTextLsGoogle,
    joiningBonusTextLsNinePage: joiningBonusTextLsNinePage,
    joiningBonusTextLsFourPage: joiningBonusTextLsFourPage,
    joiningBonusTextLsTwelvePage: joiningBonusTextLsTwelvePage,
    joiningBonusTextLsElevenPage: joiningBonusTextLsElevenPage,
    joiningBonusTextLsEightPage: joiningBonusTextLsEightPage,
    winningText: winningText
  }
  return stickyBarData
}


const useQuery = (): StickyBottomData => {
  const data = processStickyBottomData(
    useStaticQuery(graphql`
      {
        allWpPage(filter: {uri: {eq: "/sticky-bottom/"}}) {
          edges {
            node {
              id
              uri
              stickybottom {
                downloadbuttondata {
                  background {
                    color
                  }
                  button {
                    buttoncolor
                    buttoncolorstart
                    buttoncolorend
                    buttontext {
                      color
                      text
                    }
                    buttontexthindi {
                      color
                      text
                    }
                    buttontextforios{
                      color
                      text
                    }
                    buttontextbengali {
                      color
                      text
                    }
                    buttontextls14oriya {
                      color
                      text
                    }
                    buttontextls14punjabi {
                      color
                      text
                    }
                    buttontextls14malayalam {
                      color
                      text
                    }
                    buttontextkannada {
                      color
                      text
                    }
                    buttontexttamil {
                      color
                      text
                    }
                    buttontextbhojpuri {
                      color
                      text
                    }
                    buttontextgujarati {
                      color
                      text
                    }
                    buttontextmarathi {
                      color
                      text
                    }
                    buttontextlstwelve {
                      color
                      text
                    }
                    buttontextlseight {
                      color
                      text
                    }
                    buttonbackground {
                      alttext
                      imagefile {
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                    bonustext {
                      text
                      color
                    }
                    bonustextls {
                      text
                      color
                    }
                    bonustextlssixteen {
                      text
                      color
                    }
                    bonustexthindi {
                      text
                      color
                    }
                    bonustextbengali {
                      text
                      color
                    }
                    bonustextls14oriya {
                      text
                      color
                    }
                    bonustextls14punjabi {
                      text
                      color
                    }
                    bonustextls14malayalam {
                      text
                      color
                    }
                    bonustextkannada {
                      text
                      color
                    }
                    bonustexttamil {
                      text
                      color
                    }
                    bonustextbhojpuri {
                      text
                      color
                    }
                    bonustextgujarati {
                      text
                      color
                    }
                    bonustextmarathi {
                      text
                      color
                    }
                    bonustextplaystorehindi {
                      text
                      color
                    }
                    bonustextlsgoogle {
                      color
                      text
                    }
                    bonustextlsninepage {
                      color
                      text
                    }
                    bonustextlsfourpage {
                      color
                      text
                    }
                    bonustextlstwelvepage{
                      color
                      text
                    }
                    bonustextlselevenpage{
                      color
                      text
                    }
                    bonustextlseightpage{
                      color
                      text
                    }
                  }
                  
                  buttonandroidimage {
                    alttext
                    imagefile {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                  buttoniosimage{
                    alttext
                    imagefile {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                  buttonandroidlink
                  buttonioslink
                }
                imagewithtext {
                  image {
                    alttext
                    imagefile {
                      localFile {
                        url
                      }
                    }
                  }
                  description {
                    text
                  }
                }
              }
            }
          }
        }
      }  
		`)
  );
  return data;
};

export default useQuery;