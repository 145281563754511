export const DIMENS = {
	W150: 150
};

export const COLORS = {
	ALERT: {
		SUCCESS: '#1F8000',
		ERROR: '#892C21'
	}
};

export const ANIMATION_TYPES = {
	FADE_IN: 'fadeIn',
	TRANSLATE_HORIZONTALLY: 'translateHorizontally'
};

export const PROMISE_SUCCESS = {
	status: 'fulfilled',
	statusCode: 200
};

export enum CASE {
	UPPERCASE,
	LOWERCASE,
	NOCASE
}
export const ANALYTICS_CONSTANTS = {
	EVENT: 'eventTracking',
	APK_DOWNLOAD_EVENT: {
		CATEGORY: 'download',
		ACTION: 'apk download click',
		LABEL: 'apk download'
	},
	IOS_APP_STORE_EVENT: {
		CATEGORY: 'download',
		ACTION: 'ios appstore click',
		LABEL: 'ios appstore click'
	},
	PAGE_LOAD_EVENT: {
		CATEGORY: 'newWebsite',
		ACTION: 'new website load',
		LABEL: 'new website load',
	},
	CATEGORY_POPUP_DOWNLOAD: 'popup download',
	THANK_YOU_PAGE_NAME: 'thankyou',
	HOME_PAGE_NAME: 'homepage',
	HOTSTAR_PAGE_NAME: 'hotstar',
	HOTSTAR1_PAGE_NAME: 'hotstar1',
	LS_PAGE_NAME: 'lspage',
	LS_FB_PAGE_NAME: 'lsfbpage',
	LS1_PAGE_NAME: 'ls1page',
	LS2_PAGE_NAME: 'ls2page',
	LS3_PAGE_NAME: 'ls3page',
	LS4_PAGE_NAME: 'ls4page',
	LS5_PAGE_NAME: 'ls5page',
	LS6_PAGE_NAME: 'ls6page',
	LS7_PAGE_NAME: 'ls7page',
	LS8_PAGE_NAME: 'ls8page',
	LS9_PAGE_NAME: 'ls9page',
	LS10_PAGE_NAME: 'ls10page',
	LS11_PAGE_NAME: 'ls11page',
	LS12_PAGE_NAME: 'ls12page',
	LS13_PAGE_NAME: 'ls13page',
	LS14_PAGE_NAME: 'ls14page',
	LS15_PAGE_NAME: 'ls15page',
	LS16_PAGE_NAME: 'ls16page',
	LS17_PAGE_NAME: 'ls17page',
	PLAYSTORE_PAGE_NAME: 'playstore',
	PLAYSTORE_HINDI_PAGE_NAME: 'playstorehindi',
	GoldSilver_PAGE_NAME: 'goldsilverpage',
	LS_REMARKETING_PAGE_NAME: 'lsremarketing',
	LS_GOOGLE_PAGE_NAME: 'lsgoogle',
	LS_BENGALI_PAGE_NAME: 'lsbengalipage',
	LS14_BENGALI_PAGE_NAME: 'ls14bengalipage',
	LS14_FB_BENGALI_PAGE_NAME: 'ls14fbbengalipage',
	LS_KANNADA_PAGE_NAME: 'lskannadapage',
	LS14_KANNADA_PAGE_NAME: 'ls14kannadapage',
	LS14_FB_KANNADA_PAGE_NAME: 'ls14fbkannadapage',
	LS_TAMIL_PAGE_NAME: 'lstamilpage',
	LS14_TAMIL_PAGE_NAME: 'ls14tamilpage',
	LS14_fb_TAMIL_PAGE_NAME: 'ls14fbtamilpage',
	LS_BHOJPURI_PAGE_NAME: 'lsbhojpuripage',
	LS14_BHOJPURI_PAGE_NAME: 'ls14bhojpuripage',
	LS14_FB_BHOJPURI_PAGE_NAME: 'ls14fbbhojpuripage',
	LS_GUJARATI_PAGE_NAME: 'lsgujaratipage',
	LS14_GUJARATI_PAGE_NAME: 'ls14gujaratipage',
	LS14_FB_GUJARATI_PAGE_NAME: 'ls14fbgujaratipage',
	LS_MARATHI_PAGE_NAME: 'lsmarathipage',
	LS14_FB_MARATHI_PAGE_NAME: 'ls14fbmarathipage',
	LS14_MARATHI_PAGE_NAME: 'ls14marathipage',
	NOLS_BENGALI_PAGE_NAME: 'bengalinols',
	NOLS_KANNADA_PAGE_NAME: 'kannadanols',
	NOLS_GUJARATI_PAGE_NAME: 'gujaratinols',
	NOLS_MARATHI_PAGE_NAME: 'marathinols',
	LS_HINDI_PAGE_NAME: 'lshindipage',
	LS14_HINDI_PAGE_NAME: 'ls14hindipage',
	LS14_FB_HINDI_PAGE_NAME: 'ls14fbhindipage',
	LS4_HINDI_PAGE_NAME: 'ls4hindipage',
	PHONEPE_PAGE_NAME: 'phonepepage',
	LS14_ORIYA_PAGE_NAME: 'ls14oriyapage',
	LS14_FB_ORIYA_PAGE_NAME: 'ls14fboriyapage',
	LS14_PUNJABI_PAGE_NAME: 'ls14punjabipage',
	LS14_FB_PUNJABI_PAGE_NAME: 'ls14fbpunjabipage',
	LS14_MALAYALAM_PAGE_NAME: 'ls14malayalampage',
	LS14_FB_MALAYALAM_PAGE_NAME: 'ls14fbmalayalampage',
	SNL_PAGE_NAME: 'snlpage',
	CARROM_NINJA_PAGE_NAME: 'carromninja',
	TRUMP_CARD_MANIA: 'trumpcardmania',
	MINTEGRAL_PAGE_NAME: 'mintegralpage',
	ZUPEE_NEW_YEAR_CAMPAIGN: 'zupeenewyearcampaign',
	FB_EVENT_HOME_PAGE: 'PageView',
	FB_EVENT_THANKYOU_PAGE: 'Lead',
};

export enum OperatingSystem {
	ANDROID,
	IOS,
	OTHERS
}
export const THANKYOU_PAGE_PATH = '/thankyou/';
export const HOTSTAR_PAGE_PATH = 'hotstar';
export const HOTSTAR_THANKYOU_PAGE_PATH = '/thankyou/?page=hotstar';
export const LS2_THANKYOU_PAGE_PATH = '/thankyou/?page=ls2page';
export const LS3_THANKYOU_PAGE_PATH = '/thankyou/?page=ls3page';

export const MOBILE_BREAKPOINT_WIDTH = 600;

export const UPDATE_CANONICAL_PATH = {
	ls14bengalipage:'ls14-bengali',
	ls14bhojpuripage:'ls14-bhojpuri',
	ls14marathipage:'ls14-marathi',
	ls14hindipage:'ls14-hindi',
	ls14gujaratipage:'ls14-gujarati',
	ls14fbhindipage:'ls14-fb-hindi',
	ls14fbmalayalampage:'ls14-fb-malayalam',
	ls14fbpunjabipage:'ls14-fb-punjabi',
	ls14fboriyapage:'ls14-fb-oriya',
	ls14fbtamilpage:'ls14-fb-tamil',
	ls14fbbengalipage:'ls14-fb-bengali',
	ls14fbmarathipage:'ls14-fb-marathi',
	ls14fbkannadapage:'ls14-fb-kannada',
}
