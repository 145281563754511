import React, { useCallback, useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
// import { navigate } from 'gatsby';
import Bowser from "bowser";
import { StaticImage } from 'gatsby-plugin-image';

import { TextProps, WinningText } from '../../../types/types';
import './stickyDownloadButton.modules.css';
import useQuery from './useQuery';
import { ANALYTICS_CONSTANTS, OperatingSystem } from '../../../constants';
import { useState } from 'react';
import { useEffect } from 'react';
import { osDetector } from '../../../../utils/downloadLinkUtils';
import { isEmptyOrNull } from '../../../../utils/textUtils';
import AnalyticsContext from '../../../context/analytics/analyticsContext';
import PageContext from '../../../context/page/pageContext';
import {
	extraEventForGA,
	redirectCheck,
	redirectCheckNotifiation,
	redirectCheckNotifiationWithapkLocal,
	stickyNotificationActiveFunction,
	redirectToGCLID,
	redirectToVideoSectionInMobile,
	apkPromiseFunction,
	onButtonClickForMIDevice,
	whiteScreenLStenClick,
	redirectToVideoSectionInMobileandAutoPlay
} from '../../../../utils/redirectCheck';
import { useScrollHandler } from '../../../../utils/scrollHandler';
import { FbPixelEvent_Lead } from "../../../../utils/FbPixel/fbPixel";
import { debounce } from '../../../../utils/debounce';
import MobileNumberFormNotification from '../MobileNoHandlingBlocks/MobileNumberFormNotification';


interface DownloadButtonProps {
	buttonText: TextProps;
	buttonTextLs: TextProps;
	buttonTextHindi: TextProps;
	buttonTextForiOS: TextProps;
	buttonTextBengali: TextProps;
	buttonTextKannada: TextProps;
	buttonTextTamil: TextProps;
	buttonTextBhojpuri: TextProps;
	buttonTextGujarati: TextProps;
	buttonTextMarathi: TextProps;
	buttonTextLs14Oriya: TextProps;
	buttonTextLs14Punjabi: TextProps;
	buttonTextLs14Malayalam: TextProps;
	buttonTextLs12:TextProps;
	buttonTextLs8:TextProps;
	joiningBonusText: TextProps;
	downloadLink: string;
	buttonColor: string;
	image: any;
	buttonBackgroundImage: any;
	isAndroid: boolean;
	buttonColorStart: string;
	buttonColorEnd: string;
	btnDisable: any;
	handleHandGesture: any;
	handleHandGestureLSelevntwl?:any;
	setPreHandgestureLoader: any;
	handleDoubleClickCheck: any;
	setLastClickTime: any;
	lastClickTime: any;
	firstClickIgnored: any;
	setFirstClickIgnored: any;
}

const DownloadButton = (props: DownloadButtonProps) => {
	const analyticsPrefix = useContext(AnalyticsContext);


	const { pageRelatedTo, apkPromise, gclidValue, gatsbyDownloadBtnEvent, andriodLinkRef, popupThankyouRef, setPopupThankyouRef, popupShowRef, setPopupShowRef, userBucket, fallbackCase, setMobileformRef, setDownloadClick } = useContext(PageContext);

	const {
		buttonText,
		buttonTextLs,
		buttonTextHindi,
		buttonTextForiOS,
		buttonTextBengali,
		buttonTextKannada,
		buttonTextTamil,
		buttonTextBhojpuri,
		buttonTextGujarati,
		buttonTextMarathi,
		buttonTextLs14Oriya,
		buttonTextLs14Punjabi,
		buttonTextLs14Malayalam,
		buttonTextLs12,
		buttonTextLs8,
		downloadLink,
		image,
		isAndroid,
		buttonColor,
		buttonColorEnd,
		buttonColorStart,
		btnDisable,
		handleHandGesture,
		handleHandGestureLSelevntwl,
		setPreHandgestureLoader,
		handleDoubleClickCheck,
		setLastClickTime,
		lastClickTime,
		firstClickIgnored,
		setFirstClickIgnored
	} = props;

	const handleDownload = () => {

		let dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: ANALYTICS_CONSTANTS.EVENT,
			category: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.CATEGORY + ` ${pageRelatedTo}`,
			action:
				analyticsPrefix +
				(isAndroid ? ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.ACTION : ANALYTICS_CONSTANTS.IOS_APP_STORE_EVENT.ACTION),
			userBucket: userBucket ? userBucket : undefined,
			label:
				analyticsPrefix +
				(isAndroid ? ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.LABEL : ANALYTICS_CONSTANTS.IOS_APP_STORE_EVENT.LABEL)
		});
		
		if (isAndroid) {

			if (pageRelatedTo === 'ls111111page') {
				redirectToGCLID(apkPromise, gclidValue);
				extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
				FbPixelEvent_Lead();
			} else if (pageRelatedTo === 'ls4page') {
				const urlParams = new URLSearchParams(window.location.search);
				const gpsadid = urlParams.get('gps_adid');

				if (gpsadid == null || gpsadid == '') {
					redirectToVideoSectionInMobile();
					setTimeout(() => {
						redirectToGCLID(apkPromise, gclidValue);
						extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
						redirectCheckNotifiation(apkPromise);
					}, 500);

				} else {
					const redirectURLGAID = 'https://zupee.com/downloads/ls4/';
					redirectToVideoSectionInMobile();
					setTimeout(() => {
						extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
						redirectCheckNotifiation(apkPromise);
						const redtURL = 'https://app.adjust.net.in/kbw6mm4?gps_adid=' + gpsadid + '&matching=none&engagement_type=fallback_click&redirect=' + redirectURLGAID;
						window.open(redtURL);
					}, 500);

				}
			} else if (
				pageRelatedTo === 'ls5page' ||
				pageRelatedTo === 'snlpage' ||
				pageRelatedTo === 'ls4hindipage' ||
				pageRelatedTo === 'lsbengalipage' ||
				pageRelatedTo === 'lsmarathipage' ||
				pageRelatedTo === 'phonepepage' ||
				pageRelatedTo === 'zupeenewyearcampaign' ||
				pageRelatedTo === 'lsremarketingpage' ||
				pageRelatedTo === 'ls6page' ||
				pageRelatedTo === 'ls9page' ||
				pageRelatedTo === 'gujaratinols' ||
				pageRelatedTo === 'marathinols' ||
				pageRelatedTo === 'carrompage' ||
				pageRelatedTo === 'lsgoogle' ||
				pageRelatedTo === 'lspage' ||
				pageRelatedTo === 'ls1page' ||
				pageRelatedTo === 'ls16page' ||
				pageRelatedTo === 'ls17page'
			) {
				redirectToVideoSectionInMobile();
				setTimeout(() => {
					redirectCheckNotifiation(apkPromise);
					extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
				}, 500);
			} else if (pageRelatedTo === 'ls3page'){
				redirectToVideoSectionInMobileandAutoPlay();
				setTimeout(() => {
					redirectCheckNotifiation(apkPromise);
					extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
				}, 500);
			}else if (
				pageRelatedTo === "lsfbpage" ||
				pageRelatedTo === "kannadanols" ||
				pageRelatedTo === "bengalinols" ||
				pageRelatedTo === "homepagehindi" ||
				pageRelatedTo === "lsbhojpuripage" ||
				pageRelatedTo === "lsgujaratipage" ||
				pageRelatedTo === 'goldsilverpage' ||
				pageRelatedTo === 'playstore' ||
				pageRelatedTo === 'playstorehindi' ||
				pageRelatedTo === 'lskannadapage' ||
				pageRelatedTo === 'lstamilpage' ||
				pageRelatedTo === 'ls14page' ||
				pageRelatedTo === 'ls2page' ||
				pageRelatedTo === 'ls15page' ||
			    pageRelatedTo === 'tcmpage' ||
				pageRelatedTo === 'homepage'
			) {

				redirectToVideoSectionInMobile();
				setTimeout(() => {
					redirectCheckNotifiation(apkPromise);
					extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
				}, 500);
				FbPixelEvent_Lead();
			} else if (pageRelatedTo === "ls7777page") {

				setTimeout(() => {
					apkPromiseFunction(apkPromise, "_self");
					extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
				}, 500);
				// popup thank you set with true on buttonclick
				setTimeout(function () { setPopupThankyouRef(!popupThankyouRef); }, 5000);
			} else if (pageRelatedTo === "ls10page" || pageRelatedTo === "ls7page") {
				/*onButtonClickForMIDevice();
				setTimeout(() => {
					extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
				}, 500);*/
				whiteScreenLStenClick();
				redirectToVideoSectionInMobile();
				setTimeout(() => {
					redirectCheckNotifiation(apkPromise);
					extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
				}, 500);
				FbPixelEvent_Lead();

			} else if (pageRelatedTo === "ls11pageold") {
				const browser = Bowser.getParser(window.navigator.userAgent);
				const browserNameCheck = browser.getBrowser().name?.toLowerCase();
				const browserVersionCheck = browser.getBrowser().version;
				// hand gesture for chrome browser only
				if (browserNameCheck === 'chrome' && typeof (browserVersionCheck) > '85') {
					console.log("yups, it's a chrome browser");
					handleHandGesture();
					setTimeout(() => {
						setTimeout(() => {
							stickyNotificationActiveFunction();
							redirectToVideoSectionInMobile();
						}, 1000);
					}, 10000);
					setTimeout(() => {
						apkPromiseFunction(apkPromise, "_self");
						extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
					}, 500);
				} else // rest use case will same for other browser // 
				{
					console.log("nops, it's not a chrome browser");
					redirectToVideoSectionInMobile();
					setTimeout(() => {
						redirectCheckNotifiation(apkPromise);
						extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
					}, 500);
				}
			} else if (pageRelatedTo === "ls13page") {
				const urlParams = new URLSearchParams(window.location.search);
				const gpsadid = urlParams.get('gps_adid');
				if (gpsadid == null || gpsadid == '') {
					redirectToVideoSectionInMobile();
					setTimeout(() => {
						extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
						redirectCheckNotifiation(apkPromise);
					}, 500);
				} else {
					setTimeout(() => {
						const redtURL = 'https://app.adjust.net.in/kbw6mm4?gps_adid=' + gpsadid + '&matching=none&engagement_type=fallback_click&redirect=https://www.zupee.com/downloads/thankyou/';
						window.open(redtURL, "_self");
					}, 500);
					setTimeout(() => {
						redirectToVideoSectionInMobile();
						extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
						redirectCheckNotifiation(apkPromise);
					}, 100);
				}
			}else if(pageRelatedTo === 'ls12page' || pageRelatedTo === 'ls11page'){
				//FbPixelEvent_Lead();
				//redirectToVideoSectionInMobile();
				//redirectCheckNotifiation(apkPromise);
				//apkPromiseFunction(apkPromise, "_self");
				//setMobileformRef(true);
				//extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
				setDownloadClick(true);

				const browser = Bowser.getParser(window.navigator.userAgent);
				const browserNameCheck = browser.getBrowser().name?.toLowerCase();
				const browserVersionCheck = browser.getBrowser().version;
				setPreHandgestureLoader(false);
				// hand gesture for chrome browser only
				if (browserNameCheck === 'chrome' && typeof (browserVersionCheck) > '85') {
					console.log("yups, it's a chrome browser");
					handleHandGestureLSelevntwl();
					FbPixelEvent_Lead();
					setTimeout(() => {
						setTimeout(() => {
							//stickyNotificationActiveFunction();
							redirectToVideoSectionInMobile();
							
						}, 100);
					}, 500);
					setTimeout(() => {
						apkPromiseFunction(apkPromise, "_self");
						extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
						setTimeout(function () { setPopupThankyouRef(!popupThankyouRef); setMobileformRef(true); }, 10000);
					}, 500);
				} else // rest use case will same for other browser // 
				{
					console.log("nops, it's not a chrome browser");
					redirectToVideoSectionInMobile();
					setTimeout(() => {
						redirectCheckNotifiation(apkPromise);
						extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
						setTimeout(function () { setPopupThankyouRef(!popupThankyouRef); setMobileformRef(true); }, 10000);
					}, 500);
				}
				

		}else if(pageRelatedTo === 'ls8page'){
			//FbPixelEvent_Lead();
			extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
	   }  else {
				redirectCheckNotifiation(apkPromise);
				extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
			}


		} else {
			window.open(downloadLink);
		}
	};


	const handleDownloadLink = (e) => {

		let dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: ANALYTICS_CONSTANTS.EVENT,
			category: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.CATEGORY + ` ${pageRelatedTo}`,
			action: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.ACTION,
			userBucket: props.userBucket ? props.userBucket : undefined,
			label: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.LABEL
		});
		if (isAndroid) {
			//run custom apk download for every androiddownloadlink field assigned page.
			window.open(andriodLinkRef, "_self");

			//run specific functionalty for pages
			if (pageRelatedTo === 'ls14fbkannadapage' || pageRelatedTo === 'ls3page' || pageRelatedTo === 'ls6page' || pageRelatedTo === 'ls14page' || pageRelatedTo === 'ls14fbmalayalam' || pageRelatedTo === 'ls14fbtamilpage' || pageRelatedTo === 'ls14fbkannadapage' || pageRelatedTo === 'ls14fbbhojpuripage' || pageRelatedTo === 'ls14fbbengalipage' || pageRelatedTo === 'homepagehindifb14' || pageRelatedTo === 'ls14fboriyapage') {
				FbPixelEvent_Lead();
				redirectToVideoSectionInMobile();
				stickyNotificationActiveFunction();
			} else if (pageRelatedTo === 'ls1page') {
				FbPixelEvent_Lead();
				stickyNotificationActiveFunction();
			} else if (pageRelatedTo === "ls10page") {
				setTimeout(function () { setPopupThankyouRef(!popupThankyouRef); }, 500);
			} else if (pageRelatedTo === "ls15page") {
				setPopupShowRef(true);
				// popup thank you set with true on buttonclick
			} else if (pageRelatedTo === "ls8page") {
				redirectToVideoSectionInMobile();
				//FbPixelEvent_Lead();
				stickyNotificationActiveFunction();
				if (fallbackCase === true) {
					window.open(process.env.DEFAULT_APK_LINK);
					console.log("fallbackCase clicked: Mobile");
				}
			} else {
				redirectToVideoSectionInMobile();
				stickyNotificationActiveFunction();
			}

			if (pageRelatedTo === 'ls8page' ){
				localStorage.setItem('apkURL',andriodLinkRef);
				}

			// ga gtm for every page
			extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);


		} else {
			window.open(downloadLink);
			extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
		}
	}

	const debouncedHandleClick = useCallback(debounce((currentTime) => {

		let dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: ANALYTICS_CONSTANTS.EVENT,
			category: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.CATEGORY + ` ${pageRelatedTo}`,
			action: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.ACTION,
			userBucket: props.userBucket ? props.userBucket : undefined,
			label: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.LABEL
		});

		handleDoubleClickCheck();

		if (!firstClickIgnored) {
			console.log('First click ignored');
			setFirstClickIgnored(true);
			setLastClickTime(currentTime);
			// redirectToVideoSectionInMobile();

		} else if (currentTime - lastClickTime <= 5000) {

			console.log('Second click detected within 5 seconds');
		} else {
			console.log('More than 5 seconds since last click');
			setFirstClickIgnored(false);
			// redirectToVideoSectionInMobile();
		}
		setLastClickTime(currentTime);

	}, 300), [firstClickIgnored, lastClickTime]);

	const debounceHandleClick = () => {
		if (isAndroid) {
			const currentTime = new Date().getTime();
			debouncedHandleClick(currentTime);
			//apkPromiseFunction(apkPromise, "_self");
			//window.open(andriodLinkRef, "_self");
			//stickyNotificationActiveFunction();
			//FbPixelEvent_Lead();
			extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);

		} else {
			window.open(downloadLink);
			//FbPixelEvent_Lead();
			extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
		}
	};

	return (
		<>
			<div className='sticky-bottom-content-container'>
				<div className='sticky-bottom-content-div'>
					<div
						className={`sticky-bottom-download-link ${isAndroid ? 'apk-download-link' : ''} ${btnDisable ? 'disbaled-btn' : ''
							}`}
						onClick={
						   pageRelatedTo === 'ls8page'
								?
								(debounceHandleClick)
								:
								(andriodLinkRef ? handleDownload : handleDownload)
						}
					>
						<div
							style={{ background: `linear-gradient(to bottom,${buttonColorStart} 1%,${buttonColorEnd} 100%)` }}
							className='sticky-bottom-download-button-container'>
							{
								isAndroid ?
									<img src="https://static-perf1.zupee.com/wp-content/uploads/2023/06/android-icon-001.gif" alt={image.altText} width="32" height="32" className="animation-android-icon" style={{ marginRight: "15px" }} />
									: <GatsbyImage image={image?.imageFile} alt={image.altText} className='sticky-bottom-os-image' />
							}
							<p style={{ color: buttonText.color }} className='sticky-bottom-download-text sticky-bottom-p-tag'>
								{!isAndroid ? (
									<>{buttonTextForiOS.text}</>
								) : (
									<>
										{pageRelatedTo === 'homepagehindi' || pageRelatedTo === 'ls4hindipage' || pageRelatedTo === 'homepagehindifb14' || pageRelatedTo === 'playstorehindi' || pageRelatedTo === 'homepagehindi14' ? (
											<>{buttonTextHindi.text}</>
										) : (
											<>
												{pageRelatedTo === 'lsbengalipage' || pageRelatedTo === 'bengalinols' || pageRelatedTo === 'ls14bengalipage' || pageRelatedTo === 'ls14fbbengalipage' ? (
													<>{buttonTextBengali.text}</>
												) : (
													<>
														{pageRelatedTo === 'lskannadapage' || pageRelatedTo === 'kannadanols' || pageRelatedTo === 'ls14kannadapage' || pageRelatedTo === 'ls14kannadapage' ? (
															<>{buttonTextKannada.text}</>
														) : (
															<>
																{pageRelatedTo === 'lstamilpage' || pageRelatedTo === 'ls14tamilpage' || pageRelatedTo === 'ls14fbtamilpage' ? (
																	<>{buttonTextTamil.text}</>
																) : (
																	<>
																		{pageRelatedTo === 'ls14oriyapage' || pageRelatedTo === 'ls14fboriyapage' ? (
																			<>{buttonTextLs14Oriya.text}</>
																		) : (
																			<>
																				{pageRelatedTo === 'ls14punjabipage' || pageRelatedTo === 'ls14fbpunjabipage' ? (
																					<>{buttonTextLs14Punjabi.text}</>
																				) : (
																					<>
																						{pageRelatedTo === 'ls14malayalampage' || pageRelatedTo === 'ls14fbmalayalam' ? (
																							<>{buttonTextLs14Malayalam.text}</>
																						) : (
																							<>
																								{pageRelatedTo === 'lsbhojpuripage' || pageRelatedTo === 'ls14bhojpuripage' || pageRelatedTo === 'ls14fbbhojpuripage' ? (
																									<>{buttonTextBhojpuri.text}</>
																								) : (
																									<>
																										{pageRelatedTo === 'lsgujaratipage' || pageRelatedTo === 'gujaratinols' || pageRelatedTo === 'ls14gujaratipage' || pageRelatedTo === 'ls14fbgujaratipage' ? (
																											<>{buttonTextGujarati.text}</>
																										) : (
																											<>
																												{pageRelatedTo === 'lsmarathipage' || pageRelatedTo === 'marathinols' || pageRelatedTo === 'ls14marathipage' || pageRelatedTo === 'ls14fbmarathipage' ? (
																													<>{buttonTextMarathi.text}</>
																												) : (
																													<>{pageRelatedTo === 'ls12page' ? (
																														<>{buttonTextLs12.text}</>
																													) : (
																														<>{pageRelatedTo === 'ls8page' ? (
																															<>{buttonTextLs8.text}</>
																														) : (
																															<>{buttonText.text}</>
																														)}</>
																													)}</>
																												)}
																											</>
																										)}
																									</>
																								)}
																							</>
																						)}
																					</>
																				)}
																			</>
																		)}
																	</>
																)}
															</>
														)}
													</>
												)}
											</>
										)}
									</>)}
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const StickyBottomBar = (props) => {
	const { showPageStickyBottom, tncTitle, tncDescription, userBucket } = props;
	const analyticsPrefix = useContext(AnalyticsContext);
	const { andriodLinkRef, gatsbyDownloadBtnEvent,apkPromise, preHandgestureLoader, setPreHandgestureLoader } = useContext(PageContext);

	const data = useQuery();

	const {
		downloadButtonData,
		winningText,
		joiningBonusText,
		joiningBonusTextLs,
		joiningBonusTextLsSixteen,
		joiningBonusTextHindi,
		joiningBonusTextBengali,
		joiningBonusTextKannada,
		joiningBonusTextTamil,
		joiningBonusTextBhojpuri,
		joiningBonusTextGujarati,
		joiningBonusTextMarathi,
		joiningbonusTextPlaystoreHindi,
		joiningBonusTextLs14Oriya,
		joiningBonusTextLs14Punjabi,
		joiningBonusTextLs14Malayalam,
		joiningBonusTextLsGoogle,
		joiningBonusTextLsNinePage,
		joiningBonusTextLsFourPage,
		joiningBonusTextLsTwelvePage,
		joiningBonusTextLsElevenPage,
		joiningBonusTextLsEightPage
	} = data;
	const [btnImage, setBtnImage] = useState({});
	const [downloadLink, setDownloadLink] = useState('');
	const [isAndroid, setAndroid] = useState(true);
	const [isDisabled, setIsDisabled] = useState(true);
	const [checked, setChecked] = useState(false);
	const { showCheckboxToDownload } = useContext(PageContext);
	const [showTncDescription, setShowTncDescription] = useState(false);
	const { pageRelatedTo } = useContext(PageContext);
	const defaultScrollLength = pageRelatedTo === 'playstore' ? 0 : 220;
	const scroll = useScrollHandler(defaultScrollLength);
	const [isHandGestureActive, setIsHandGestureActive] = useState(false);
	const [loaderBeforeHandGesture, setLoaderBeforeHandGesture] = useState(false);
	const [buttonClickedInThreshold, setButtonClickedInThreshold] = useState(false);
	

	const [lastClickTime, setLastClickTime] = useState(0);
	const [firstClickIgnored, setFirstClickIgnored] = useState(false);

	const canBeSubmitted = () => {
		return checked ? setIsDisabled(true) : setIsDisabled(false);
	};

	const onCheckboxClick = () => {
		setChecked(!checked);
		return canBeSubmitted();
	};

	useEffect(() => {
		if (osDetector() === OperatingSystem.ANDROID) {
			setBtnImage(downloadButtonData?.button?.buttonAndroidImage);
			// setDownloadLink(downloadLink);
			setAndroid(true);
		} else if (osDetector() === OperatingSystem.IOS) {
			setBtnImage(downloadButtonData?.button?.buttonIosImage);
			setDownloadLink(downloadButtonData?.button?.buttonIosLink);
			setAndroid(false);
		}

		if(pageRelatedTo === "ls12page" || pageRelatedTo === "ls11page"){
     		setTimeout(() => setPreHandgestureLoader(true), 2000);
	    	//setTimeout(() => setPreHandgestureLoader(false), 10000);
		}
	}, []);


	const showTncData = () => {
		setShowTncDescription(!showTncDescription);
	};
	

	const handleHandGesture = () => {
		setLoaderBeforeHandGesture(!loaderBeforeHandGesture);
		setTimeout(() => setLoaderBeforeHandGesture(false), 3000);
		setTimeout(() => {
			// enabling the hand gesture
			setIsHandGestureActive(!isHandGestureActive);
			// disabling the hand gesture after 7 sec
			setTimeout(() => setIsHandGestureActive(false), 7000);
		}, 3000);
	}

	const handleHandGestureLSelevntwl = () => {
		setLoaderBeforeHandGesture(!loaderBeforeHandGesture);
		setTimeout(() => setLoaderBeforeHandGesture(false), 3000);
		setTimeout(() => {
			// enabling the hand gesture
			setIsHandGestureActive(!isHandGestureActive);
			// disabling the hand gesture after 7 sec
			setTimeout(() => setIsHandGestureActive(false), 4500);
		}, 6000);
	}


	const handleDoubleClickCheck = () => {
		let isUserComeAgain = localStorage.getItem('userComeAgain');
		if (isUserComeAgain === '' || isUserComeAgain === null) {
			localStorage.setItem('userComeAgain', 'true');
		}
		if (isUserComeAgain === 'true') {
			setButtonClickedInThreshold(!buttonClickedInThreshold);
			const bodyRef = document.querySelector("body");
			bodyRef?.classList.add("body-overflow-hidden");
		}
	}

	const handleDownloadClick = () => {

		let dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: ANALYTICS_CONSTANTS.EVENT,
			category: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.CATEGORY + ` ${pageRelatedTo}`,
			action:
				analyticsPrefix +
				(isAndroid ? ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.ACTION : ANALYTICS_CONSTANTS.IOS_APP_STORE_EVENT.ACTION),
			userBucket: userBucket ? userBucket : undefined,
			label:
				analyticsPrefix +
				(isAndroid ? ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.LABEL : ANALYTICS_CONSTANTS.IOS_APP_STORE_EVENT.LABEL)
		});

		

		if (isAndroid) {
			if (pageRelatedTo === 'ls13page') {
				apkPromiseFunction(apkPromise, "_self");
				redirectToVideoSectionInMobile();
				stickyNotificationActiveFunction();
				extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
			}
		} else {
			window.open(downloadLink);
			extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent);
		}
	}


	return (
		<>
			{/*((pageRelatedTo === 'ls12page') && buttonClickedInThreshold) ?
				<MobileNumberFormFirstStage />
				: ""
			*/}
			{((pageRelatedTo === 'ls8page') && buttonClickedInThreshold) ?
				<MobileNumberFormNotification apkPromise={apkPromise}/>
				: ""
			}
			{pageRelatedTo === "ls11page" && loaderBeforeHandGesture
				?
				<>
					<div className="loader enable">
						<div className="loader__spinner"></div>
						<div className="loader__text">Downloading</div>
					</div>
				</>
				: ""
			}
			{/*(pageRelatedTo === "ls11page") && isHandGestureActive
				?
				<>
					<div className='enable-hand-gesture'>
						<StaticImage src="https://assets.zupee.com/downloads/assets/icon-hand.webp" alt=" hand-icon" className="hand-icon" />
						<div className="text">Click there</div>
					</div>
				</>
				: ""
			*/}
			{(pageRelatedTo === "ls12page" || pageRelatedTo === "ls11page") && isHandGestureActive
				?
				<>
					<div className='enable-hand-gesture'>
						<StaticImage src="https://assets.zupee.com/downloads/assets/handclickafter.webp" alt=" hand-icon" className="hand-icon" />
						{/*<div className="text">Click there</div>*/}
					</div>
				</>
				: ""
			}
			
			{(pageRelatedTo === "ls12page" || pageRelatedTo === "ls11page") && preHandgestureLoader &&!isHandGestureActive && <div className={`handgesturepreenable handactive `} />}

			<div className={`sticky-bottom-container ${pageRelatedTo === 'playstore' || pageRelatedTo === 'ls12page' || pageRelatedTo === 'ls11page' ? 'sticky-bottom-active' : ''} ${scroll ? 'sticky-bottom-active' : ''}`} >
				{pageRelatedTo === 'ls13page' &&
					<div className='overlay-div apk-download-link' onClick={handleDownloadClick}></div>
				}
				<span className={`sticky-bottom-joining-bonus-blink-text ${showPageStickyBottom !== 'true' ? '' : 'lspagesblinktext'} `}>
					{pageRelatedTo === 'homepagehindi' || pageRelatedTo === 'ls4hindipage' || pageRelatedTo === 'homepagehindi14' || pageRelatedTo === 'homepagehindifb14' ? (
						<>{joiningBonusTextHindi?.text}</>
					) : (
						<>{pageRelatedTo === 'lsbengalipage' || pageRelatedTo === 'bengalinols' || pageRelatedTo === 'ls14bengalipage' || pageRelatedTo === 'ls14fbbengalipage' ? (
							<>{joiningBonusTextBengali?.text}</>
						) : (
							<>{pageRelatedTo === 'lskannadapage' || pageRelatedTo === 'kannadanols' || pageRelatedTo === 'ls14kannadapage' || pageRelatedTo === 'ls14fbkannadapage' ? (
								<>{joiningBonusTextKannada?.text}</>
							) : (
								<>{pageRelatedTo === 'ls14oriyapage' || pageRelatedTo === 'ls14fboriyapage' ? (
									<>{joiningBonusTextLs14Oriya?.text}</>
								) : (
									<>{pageRelatedTo === 'ls14punjabipage' || pageRelatedTo === 'ls14fbpunjabipage' ? (
										<>{joiningBonusTextLs14Punjabi?.text}</>
									) : (
										<>{pageRelatedTo === 'ls14malayalampage' || pageRelatedTo === 'ls14fbmalayalam' ? (
											<>{joiningBonusTextLs14Malayalam?.text}</>
										) : (
											<>{pageRelatedTo === 'lstamilpage' || pageRelatedTo === 'ls14tamilpage' || pageRelatedTo === 'ls14fbtamilpage' ? (
												<>{joiningBonusTextTamil?.text}</>
											) : (
												<>{pageRelatedTo === 'lsbhojpuripage' || pageRelatedTo === 'ls14bhojpuripage' || pageRelatedTo === 'ls14fbbhojpuripage' ? (
													<>{joiningBonusTextBhojpuri?.text}</>
												) : (
													<>{pageRelatedTo === 'lsgujaratipage' || pageRelatedTo === 'gujaratinols' || pageRelatedTo === 'ls14gujaratipage' || pageRelatedTo === 'ls14fbgujaratipage' ? (
														<>{joiningBonusTextGujarati?.text}</>
													) : (
														<>{pageRelatedTo === 'lsmarathipage' || pageRelatedTo === 'marathinols' || pageRelatedTo === 'ls14marathipage' || pageRelatedTo === 'ls14fbmarathipage' ? (
															<>{joiningBonusTextMarathi?.text}</>
														) : (
															<>{
																pageRelatedTo === 'lsgoogle' ? (
																	<>{joiningBonusTextLsGoogle?.text}</>
																) : (
																	<>{pageRelatedTo === 'ls4page' ? (
																		<>{joiningBonusTextLsFourPage?.text}</>
																	) : (
																		<>
																			{pageRelatedTo === 'playstorehindi' ? (
																				<>{joiningbonusTextPlaystoreHindi?.text}</>
																			) : (
																				<> {pageRelatedTo === 'ls1page' ? (
																				   <>{joiningBonusTextLs?.text}</>
																				    ) : (
																						<> {pageRelatedTo === 'ls16page' ? (
																							<>{joiningBonusTextLsSixteen?.text}</>  
																							 ) : (
																							 <>{pageRelatedTo === 'ls9page' ? (  
																								<>{joiningBonusTextLsNinePage?.text}</>
																							      ) : (
																									<>{pageRelatedTo === 'ls12page' ? (  
																										<>{joiningBonusTextLsTwelvePage?.text}</>
																										  ) : (
																											<>{pageRelatedTo === 'ls8page' ? (  
																												<>{joiningBonusTextLsEightPage?.text}</>
																												  ) : (
																													<>{pageRelatedTo === 'ls11page' ? (  
																														<>{joiningBonusTextLsElevenPage?.text}</>
																														  ) : (
																															<>{joiningBonusText?.text}</>
																													 )}</>
																											 )}</>
																									 )}</>
																							 )}</>
																							 )}
																						 </>
																					)}
																				</>
																			)}
																		</>
																	)}</>
																)}</>
														)}</>
													)}</>
												)}</>
											)}</>
										)}</>
									)}</>
								)}</>
							)}</>
						)}</>
					)}
				</span>

				{/*pageRelatedTo === 'ls4page' && isAndroid ? <p className='outsidetext'>Allow Outside Google Playstore app download</p> : ''*/}
				{showCheckboxToDownload && isAndroid && (
					<>
						<div className='checkbox-content'>
							<input type='checkbox' className='checkbox' id='checkbox' onClick={onCheckboxClick} />
							<label htmlFor='checkbox' className={`checkboxContent ${!isDisabled ? 'active' : ''}`}>
								Allow Outside Google Playstore App Download
							</label>
						</div>
					</>
				)}
				
				<DownloadButton
					downloadLink={downloadLink}
					buttonText={downloadButtonData?.button?.buttonText}
					buttonTextHindi={downloadButtonData?.button?.buttonTextHindi}
					buttonTextForiOS={downloadButtonData?.button?.buttonTextForiOS}
					buttonTextBengali={downloadButtonData?.button?.buttonTextBengali}
					buttonTextLs14Oriya={downloadButtonData?.button?.buttonTextLs14Oriya}
					buttonTextLs14Punjabi={downloadButtonData?.button?.buttonTextLs14Punjabi}
					buttonTextLs14Malayalam={downloadButtonData?.button?.buttonTextLs14Malayalam}
					buttonTextKannada={downloadButtonData?.button?.buttonTextKannada}
					buttonTextTamil={downloadButtonData?.button?.buttonTextTamil}
					buttonTextBhojpuri={downloadButtonData?.button?.buttonTextBhojpuri}
					buttonTextGujarati={downloadButtonData?.button?.buttonTextGujarati}
					buttonTextMarathi={downloadButtonData?.button?.buttonTextMarathi}
					buttonTextLs12={downloadButtonData?.button?.buttonTextLs12}
					buttonTextLs8={downloadButtonData?.button?.buttonTextLs8}
					image={btnImage}
					buttonColor={downloadButtonData?.button?.buttonColor}
					joiningBonusText={joiningBonusText}
					buttonBackgroundImage={downloadButtonData?.button?.buttonBackgroundImage}
					isAndroid={isAndroid}
					buttonColorStart={downloadButtonData?.button?.buttonColorStart}
					buttonColorEnd={downloadButtonData?.button?.buttonColorEnd}
					btnDisable={showCheckboxToDownload && !checked && isAndroid}
					userBucket={userBucket}
					handleHandGesture={handleHandGesture}
					handleHandGestureLSelevntwl={handleHandGestureLSelevntwl}
					setPreHandgestureLoader={setPreHandgestureLoader}
					handleDoubleClickCheck={handleDoubleClickCheck}
					lastClickTime={lastClickTime}
					setLastClickTime={setLastClickTime}
					firstClickIgnored={firstClickIgnored}
					setFirstClickIgnored={setFirstClickIgnored}
				/>
				{showPageStickyBottom === 'true' ? (
					<div
						className={`tnc-text ${showPageStickyBottom === 'true' && showTncDescription === true ? 'active' : ''}`}
						onClick={showTncData}>
						*T&C
					</div>
				) : (
					''
				)}

				{showPageStickyBottom === 'true' && showTncDescription === true ? (
					<div className='tnc-description-content'>{showPageStickyBottom !== 'true' ? '' : tncDescription}</div>
				) : (
					''
				)}
				{pageRelatedTo === 'phonepepage' && <div className='tnc-text'>*T&C</div>}

				{/* <WinningTextComponent {...winningText} /> */}
			</div>
		</>
	);
};

const WinningTextComponent = (props: WinningText) => {
	return (
		<div className='sticky-bottom-section-winning-text-container'>
			{props.image && <img src={props.image.imageFile} alt={props.image.altText} className='sticky-bottom-winning-text-gif' />}
			{props.text && (
				<p style={{ color: props?.text?.color ?? '#FFF' }} className='sticky-bottom-winning-text-field sticky-bottom-p-tag'>
					{props?.text?.text}
				</p>
			)}
		</div>
	);
};

export default StickyBottomBar;